import { FormControl, FormControlLabel, Switch, Tooltip } from '@mui/material';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import styles from './Residency.module.scss';
import { Field } from '@ui/Field/Field';
import { TextInput } from '@ui/TextInput/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { ResidentContext } from '@features/club/context/ResidentContext';
import { ExcludeButton } from './ExcludeButton/ExcludeButton';
import { ExcludeConfirmation } from './ExcludeConfirmation/ExcludeConfirmation';
import { ExcludedSuccess } from './ExcludeSuccess/ExcludedSuccess';
import { useNavigate } from 'react-router-dom';
import { api } from '@lib/api';
import {
  createResidentStatus,
  fetchCardsPage,
  fetchManagement,
  fetchResidentStatuses,
  removeResidentStatus,
  updateResidentStatus,
} from '../../../store/club';
import { InviteModal } from '../../ManagementModal/InviteModal/InviteModal';
import { SuccessModal } from '@ui/SuccessModal/SuccessModal';
import { EditableTagList } from '../../../../../components/ui/EditableTagList/EditableTagList';
import { AppButton } from '@ui/AppButton/AppButton';
import { Help, Message } from '@mui/icons-material';
import { ValidationContext } from '../../../context/ValidationContext';
import { FieldError } from '../../../../../components/ui/FieldError/FieldError';
import { MAX_RESIDENT_STATUS_COUNT } from '../../../../../data/constants';
import { ActualRequest } from './ActualRequest/ActualRequest';
import { ConfirmModal } from '../../../../../components/ui/ConfirmModal/ConfirmModal';
import { formatPrice } from '../../../../../util/format';
import { plural } from '../../../../../util/string';
import { mnt } from '../../../../../util/moment';
import { sortBy } from 'lodash';

export const Residency = ({ isAdd }) => {
  const { data: resident, update } = useContext(ResidentContext);
  const { errors, touched } = useContext(ValidationContext);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [archiveSuccess, setArchiveSuccess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { residentStatuses } = useSelector((state) => state.club);
  const [showInvite, setShowInvite] = useState(false);
  const [showSuccessInvite, setShowSuccessInvite] = useState(false);
  const [orders, setOrders] = useState([]);
  const [isFriend, setIsFriend] = useState(!!resident.isFriend);
  const { community } = useSelector((state) => state.community);
  const [showFriendConfirm, setShowFriendConfirm] = useState(false);

  const isRestore = resident?.isArchive;

  const fetchOrders = async () => {
    const { data } = await api.get(`/dashboard/orders/user/${resident.userId}`);
    setOrders(
      sortBy(
        data.filter((order) =>
          ['approved', 'created', 'rejected', 'progress'].includes(order.status)
        ),
        'updatedAt'
      ).reverse()
    );
  };

  const excludeResident = async () => {
    setShowConfirmation(false);
    await api.patch(`/dashboard/club/archive/`, {
      cardId: resident.cardId,
      isArchive: !isRestore,
    });
    setArchiveSuccess(true);
  };

  const successClosed = () => {
    setArchiveSuccess(false);
    dispatch(fetchCardsPage('', isRestore));
    navigate('/club/');
  };

  const excludeButtonClicked = () => {
    if (isRestore) {
      excludeResident();
    } else {
      setShowConfirmation(true);
    }
  };

  const invite = async () => {
    const cardsIds = [resident.cardId];
    await api.post(`/dashboard/club/invite/`, {
      cardsIds,
    });
    dispatch(fetchManagement());
    setShowSuccessInvite(true);
    setShowInvite(false);
  };

  const getStatusLabel = () => {
    let primary = {
      verified: 'Зарегистрирован',
      registered: 'Зарегистрирован',
      invited: 'Приглашение отправлено',
      unauthorized: 'Не зарегистрирован',
      declined: 'Приглашение отклонено',
      admin: 'Зарегистрирован',
      manager: 'Зарегистрирован',
      resident: 'Зарегистрирован',
    }[resident.status];
    if (
      !resident.userId &&
      resident.isInviteSent &&
      resident.status !== 'declined'
    ) {
      primary = 'Приглашение отправлено';
    }
    if (resident.isArchive) {
      primary = 'В архиве';
    }
    return primary;
  };

  const optionChanged = (oldValue, newValue) => {
    dispatch(updateResidentStatus(oldValue, newValue));
  };

  const optionRemoved = (option) => {
    dispatch(removeResidentStatus(option));
  };

  const optionCreated = (option) => {
    dispatch(createResidentStatus(option));
  };

  const removeResident = async () => {
    await api.delete(`/dashboard/club/cards/${resident.cardId}`);
    dispatch(fetchCardsPage('', true));
    navigate('/club/');
  };

  const inviteAgain = async () => {
    if (resident.isUserExists) {
      navigate(`/club/invite-existing-from-card/${resident.cardId}/?`);
    } else {
      setShowInvite(true);
    }
  };

  const getOrderLabel = (order) => {
    let period = `${order.period} ${plural(order.period, [
      'месяц',
      'месяца',
      'месяцев',
    ])}`;
    if (order.period === 1) {
      period = 'месяц';
    }
    return {
      approved: `Оплачено ${formatPrice(order.amount)} за ${period}`,
      created: `Ожидает оплаты ${formatPrice(order.amount)} за ${period}`,
      progress: `Оплата ${formatPrice(order.amount)} за ${period} в процессе`,
      rejected: `Оплата ${formatPrice(order.amount)} за ${period} отклонена`,
    }[order.status];
  };

  const getOrderDate = (order) => {
    return mnt(order.updatedAt).format('DD.MM.YYYY, HH:mm');
  };

  const toggleIsFriend = () => {
    setIsFriend((prev) => !prev);
    setShowFriendConfirm(false);
  };

  useEffect(() => {
    update('isFriend', isFriend);
  }, [isFriend]);

  useEffect(() => {
    dispatch(fetchResidentStatuses());
    fetchOrders();
  }, []);

  return (
    <>
      <div className={styles.residency}>
        <SectionHeader
          title="Резидентство"
          description="Управление тегами резидента на платформе, информация о запросах, ваши комментарии"
        />
        <div className={styles.form}>
          {!community.isPublic && community.isPaid && (
            <Field>
              <FormControlLabel
                control={
                  <Switch
                    checked={isFriend}
                    onChange={() => setShowFriendConfirm(true)}
                  />
                }
                label={
                  <div className={styles.friendLabel}>
                    <span>Друг сообщества</span>
                    <Tooltip
                      title={
                        `Друг сообщества — участник, который имеет полный доступ к ` +
                        `странице сообщества бесплатно. Если вы выключите статус ` +
                        `друга сообщества, то участник переместится в список ` +
                        `“Ожидается оплата” в Управлении участниками.`
                      }
                    >
                      <Help className={styles.frientIcon} />
                    </Tooltip>
                  </div>
                }
              />
            </Field>
          )}
          <Field label="Теги">
            <EditableTagList
              selected={resident.statuses || []}
              onChange={(items) => update('statuses', items)}
              options={residentStatuses}
              onOptionChange={optionChanged}
              onOptionRemove={optionRemoved}
              onOptionCreate={optionCreated}
              max={MAX_RESIDENT_STATUS_COUNT}
            />
          </Field>
          <Field label="Статус пользователя">
            <div className={styles.status}>
              <FormControl fullWidth>
                <TextInput disabled value={getStatusLabel()} />
              </FormControl>
              {(resident.status === 'invited' || resident.isInviteSent) &&
                !resident.userId && (
                  <div className={styles.inviteAgain}>
                    <AppButton
                      startIcon={<Message />}
                      variant="contained"
                      color="secondary"
                      onClick={inviteAgain}
                    >
                      Отправить еще раз
                    </AppButton>
                  </div>
                )}
            </div>
          </Field>
          <Field label="Актуальный запрос">
            <ActualRequest />
          </Field>
          <Field label="Комментарий">
            <FormControl fullWidth>
              <TextInput
                value={resident.comments}
                onChange={(ev) => update('comments', ev.target.value)}
                error={touched.submit && errors.comments}
              />
              {touched.submit && errors.comments && (
                <FieldError>{errors.comments}</FieldError>
              )}
            </FormControl>
          </Field>
          <Field label="История платежей">
            {orders.map((order) => (
              <div className={styles.order}>
                <div className={styles.label}>{getOrderLabel(order)}</div>
                <div className={styles.date}>{getOrderDate(order)}</div>
              </div>
            ))}
          </Field>
        </div>
        <br />
        <div className={styles.exclude}>
          {!community.isPublic && community.isPaid ? (
            <div className={styles.excludePlaceholder}>
              Если участник нарушает правила и вы хотите исключить его из
              сообщества, то{' '}
              <a
                href="https://t.me/OgonSupportBot"
                target="_blank"
                rel="noreferrer"
              >
                обратитесь в поддержку
              </a>
              .
            </div>
          ) : (
            <ExcludeButton
              onClick={excludeButtonClicked}
              isRestore={isRestore}
              onRemove={() => setShowRemoveConfirmation(true)}
            />
          )}
        </div>
      </div>
      {showInvite && (
        <InviteModal
          onCancel={() => setShowInvite(false)}
          onSend={invite}
          items={[
            {
              name: `${resident?.common?.about?.firstName} ${resident?.common?.about?.lastName}`,
              email: resident?.common?.contacts?.emails[0],
            },
          ]}
        />
      )}
      {showSuccessInvite && (
        <SuccessModal
          description="Приглашения отправлены."
          onClose={() => setShowSuccessInvite(false)}
        />
      )}
      {showConfirmation && (
        <ExcludeConfirmation
          onConfirm={excludeResident}
          onCancel={() => setShowConfirmation(false)}
        />
      )}
      {archiveSuccess && (
        <ExcludedSuccess onClose={successClosed} isRestore={isRestore} />
      )}
      {showRemoveConfirmation && (
        <ConfirmModal
          title="Удаление карточки резидента"
          description={`Вы действительно хотите удалить резидента ${resident.common.about.firstName} ${resident.common.about.lastName}?`}
          onDecline={() => setShowRemoveConfirmation(false)}
          onAccept={removeResident}
        />
      )}
      {showFriendConfirm && (
        <ConfirmModal
          title={
            isFriend
              ? 'Выключить статус друга сообщества?'
              : 'Назначить другом сообщества?'
          }
          description={
            isFriend
              ? 'Страница сообщества снова станет доступна для участника только после оплаты. Его карточка переместится в список «Ожидается оплата» в Управлении участниками.'
              : 'Участник получит полный доступ к странице сообщества бесплатно. Но вы всегда сможете снять статус друга сообщества с участника в его карточке в разделе «Резидентство».'
          }
          onAccept={toggleIsFriend}
          width={400}
          acceptTitle={
            isFriend
              ? 'Выключить статус друга сообщества'
              : 'Назначить другом сообщества'
          }
          onDecline={() => setShowFriendConfirm(false)}
        />
      )}
    </>
  );
};
