import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import styles from './ResidentModal.module.scss';
import {
  AccessibilityNew,
  BusinessCenter,
  CalendarToday,
  Close,
  Group,
  Home,
  Insights,
  PieChart,
  Redeem,
} from '@mui/icons-material';
import { SidebarTabs } from '@ui/SidebarTabs/SidebarTabs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Profile } from './Profile/Profile';
import { Events } from './Events/Events';
import { Residency } from './Residency/Residency';
import { Karma } from './Karma/Karma';
import { AppButton } from '@ui/AppButton/AppButton';
import { ResidentProvider } from '../../context/ResidentContext';
import { cloneDeep, set } from 'lodash';
import { api } from '@lib/api';
import { useDispatch } from 'react-redux';
import { fetchManagement, updateCardInList } from '../../store/club';
import { updateOptions } from '../../../auth/store/auth';
import { Resource } from './Resource/Resource';
import { InviteModal } from '../ManagementModal/InviteModal/InviteModal';
import { SuccessModal } from '@ui/SuccessModal/SuccessModal';
import { DEFAULT_RESIDENT_DATA } from '../../data/default-resident-data';
import { CloseConfirmation } from '../../../../components/ui/CloseConfirmation/CloseConfirmation';
import { isEqual } from 'lodash';
import { usePageTitle } from '../../../../hooks/usePageTitle';
import { ValidationProvider } from '../../context/ValidationContext';
import moment from 'moment/moment';
import { LobbyResource } from './Resource/LobbyResource/LobbyResource';
import { validateWebsite } from '../../../../lib/validation';
import { formatTelegram } from '../../../../util/format';

const query = require('ooi/query');

export const ResidentModal = () => {
  const { cardId } = useParams();
  const isAdd = !cardId;
  const [tab, setTab] = useState('common');
  const [showInvite, setShowInvite] = useState(false);
  const [showSuccessInvite, setShowSuccessInvite] = useState(false);
  const navigate = useNavigate();
  const queryData = query(window.location.search);
  const redirectTo = decodeURIComponent(queryData.redirectTo || '/club/');
  const [invalidTabs, setInvalidTabs] = useState({});
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [data, setData] = useState(
    isAdd ? cloneDeep(DEFAULT_RESIDENT_DATA) : null
  );
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const dispatch = useDispatch();
  const pageTitle = usePageTitle();
  const [initData, setInitData] = useState(null);

  const onCancel = () => {
    if (isChanged()) {
      setShowCloseConfirmation(true);
    } else {
      closeModal();
    }
  };

  const isChanged = () => {
    return !isEqual(initData, data);
  };

  const closeModal = () => {
    navigate(redirectTo);
  };

  const fetchResident = async () => {
    const response = await api.get(`/dashboard/club/cards/${cardId}`);
    setData(response.data);
    setInitData(cloneDeep(response.data));
    pageTitle(() => {
      const {
        common: {
          about: { firstName, lastName },
        },
      } = response.data;
      return `${firstName} ${lastName}`;
    });
  };

  useEffect(() => {
    if (isAdd) {
      pageTitle('Добавление резидента');
    }
  }, []);

  const tabs = [
    {
      name: 'common',
      icon: Home,
      title: 'Профиль',
      isInvalid:
        invalidTabs.personal ||
        invalidTabs.jobs ||
        invalidTabs.educations ||
        invalidTabs.projects,
    },
    {
      name: 'resources',
      icon: Group,
      title: 'Ресурсы',
      items: [
        {
          name: 'spheres',
          title: 'Сферы',
          icon: BusinessCenter,
        },
        {
          name: 'competences',
          title: 'Навыки',
          icon: PieChart,
        },
        {
          name: 'lobbies',
          title: 'Контакты',
          icon: Insights,
        },
      ],
    },
    {
      name: 'events',
      icon: CalendarToday,
      title: 'Мероприятия',
    },
    {
      name: 'karma',
      icon: Redeem,
      title: 'Рейтинг кармы',
    },
    {
      name: 'residency',
      icon: AccessibilityNew,
      title: 'Резидентство',
      isInvalid: invalidTabs.residency,
    },
  ];

  const getInvalidTabs = () => {
    if (!touched.submit) return {};
    const keys = Object.keys(errors);
    const jobs = !!keys.find((key) => key.startsWith('jobs.'));
    const educations = !!keys.find((key) => key.startsWith('educations.'));
    const projects = !!keys.find((key) => key.startsWith('projects.'));
    const residency = !!keys.includes('comments');
    const personal = !!keys.filter(
      (key) =>
        !['jobs', 'educations', 'projects', 'comments'].includes(
          key.split('.')[0]
        )
    ).length;
    return {
      jobs,
      educations,
      projects,
      residency,
      personal,
    };
  };

  const validateResident = () => {
    const errors = {};
    try {
      const { comments, common } = data;
      const { firstName, lastName, about } = common?.about || {};
      const { bdate } = common?.personal || {};
      const { phones, emails, websites } = common?.contacts || {};
      const { jobs, educations, projects } = common || {};
      if (!firstName) {
        errors.firstName = 'Укажите имя';
      } else if (firstName.length < 2) {
        errors.firstName = 'Минимальное количество символов - 2';
      } else if (firstName.length > 39) {
        errors.firstName = 'Максимальное количество символов - 39';
      } else if (!/^[а-яА-Я]+$/.test(firstName)) {
        errors.firstName = 'В написании имени используйте кириллицу';
      }
      if (!lastName) {
        errors.lastName = 'Укажите фамилию';
      } else if (lastName.length < 2) {
        errors.lastName = 'Минимальное количество символов - 2';
      } else if (lastName.length > 39) {
        errors.lastName = 'Максимальное количество символов - 39';
      } else if (!/^[а-яА-Я]+$/.test(lastName)) {
        errors.lastName = 'В написании фамилии используйте кириллицу';
      }
      if (about) {
        if (about.length < 15) {
          errors.about = 'Минимальная количество символов - 15';
        }
        if (about.length > 280) {
          errors.about = 'Максимальное количество символов - 280';
        }
      }
      for (const website of websites) {
        if (
          website !== null &&
          (!validateWebsite(website) || website.length > 78)
        ) {
          errors.websites = 'Введена некорректная ссылка';
        }
      }
      if (!bdate || bdate === '__.__.____') {
      } else if (!/^\d{2}\.\d{2}\.\d{4}$/.test(bdate)) {
        errors.bdate = 'Укажите корректную дату';
      } else {
        const date = moment(bdate, 'DD.MM.YYYY');
        const now = moment();
        const maxYear = now.year() - 18;
        const minYear = now.year() - 100;
        if (date.year() < minYear) {
          errors.bdate = `Дата рождения не может быть раньше ${minYear}`;
        }
        if (date.year() > maxYear) {
          errors.bdate = `Дата рождения не может быть позже ${maxYear}`;
        }
      }
      jobs?.forEach((job, i) => {
        const { place, position, fieldsIds } = job;
        if (!position?.trim() && !place?.trim() && !fieldsIds?.length) return;
        if (!place?.trim()) {
          errors[`jobs.${i}.place`] = 'Укажите компанию';
        }
        if (place) {
          if (place.length < 2) {
            errors[`jobs.${i}.place`] = 'Минимальное количество символов - 2';
          }
          if (place.length > 80) {
            errors[`jobs.${i}.place`] = 'Максимальное количество символов - 80';
          }
        }
        if (position) {
          if (position.length < 2) {
            errors[`jobs.${i}.position`] =
              'Минимальное количество символов - 2';
          }
          if (position.length > 80) {
            errors[`jobs.${i}.position`] =
              'Максимальное количество символов - 80';
          }
        }
        if (fieldsIds?.length > 5) {
          errors[`jobs.${i}.fieldsIds`] = 'Максимальное количество - 5';
        }
      });
      educations?.forEach((edu, i) => {
        const { chair, city, country, faculty, position, university, year } =
          edu;
        if (
          !chair &&
          !city &&
          !country &&
          !faculty &&
          !position &&
          !university &&
          !year
        )
          return;
        if (!country) {
          errors[`educations.${i}.country`] = 'Укажите страну';
        }
        if (!city) {
          errors[`educations.${i}.city`] = 'Укажите город';
        }
        if (!university) {
          errors[`educations.${i}.university`] = 'Укажите ВУЗ';
        }
        if (position) {
          if (position.length < 2) {
            errors[`educations.${i}.position`] =
              'Минимальное количество символов - 2';
          }
          if (position.length > 80) {
            errors[`educations.${i}.position`] =
              'Максимальное количество символов - 80';
          }
        }
      });
      projects.forEach((proj, i) => {
        const { description, finishYear, link, name, startYear, untilNow } =
          proj;
        if (
          !description &&
          !finishYear &&
          !link &&
          !name &&
          !startYear &&
          !untilNow
        )
          return;
        if (!name) {
          errors[`projects.${i}.name`] = 'Укажите название проекта';
        }
        if (untilNow) {
          if (!startYear) {
            errors[`projects.${i}.period`] = `Укажите дату начала`;
          }
        } else if (startYear || finishYear) {
          if (!startYear) {
            errors[`projects.${i}.period`] = 'Укажите дату начала';
          }
          if (!finishYear) {
            errors[`projects.${i}.period`] = 'Укажите дату окончания';
          }
          if (startYear && finishYear) {
            const [sMonth, sYear] = startYear.split('-');
            const [fMonth, fYear] = finishYear.split('-');
            const start = moment({
              year: sYear,
              month: sMonth - 1,
            });
            const finish = moment({
              year: fYear,
              month: fMonth - 1,
            });
            if (start.toDate().getTime() > finish.toDate().getTime()) {
              errors[`projects.${i}.period`] =
                'Дата начала не должна быть позже даты окончания';
            }
          }
        }
        if (link && !/.+\..+/.test(link)) {
          errors[`projects.${i}.link`] = 'Укажите корректную ссылку';
        }
      });
      if (comments && comments.length > 2000) {
        errors.comments = 'Максимальное количество символов - 2000';
      }
    } catch (err) {}
    return errors;
  };

  const touch = (name) => {
    setTouched((prev) => ({ ...prev, [name]: true }));
  };

  const saveResident = async () => {
    touch('submit');
    const errors = validateResident();
    setErrors(errors);
    if (Object.keys(errors).length) {
      return;
    }
    const item = cloneDeep(data);
    if (!item?.common.about.about) {
      delete item.common.about.about;
    }
    if (
      !item?.common.personal.bdate ||
      item?.common.personal.bdate === '__.__.____'
    ) {
      delete item.common.personal.bdate;
    }
    if (!item?.common.personal.city) {
      delete item.common.personal.city;
    }
    if (
      item.common?.educations?.length === 1 &&
      !item.common?.educations[0]?.country
    ) {
      item.common.educations = [];
    }
    if (
      item.common?.projects?.length === 1 &&
      !item.common?.projects[0]?.name
    ) {
      item.common.projects = [];
    }
    if (!data.userId) {
      item.common.contacts.phones = item.common.contacts.phones.filter(
        (val) => val
      );
      item.common.contacts.emails = item.common.contacts.emails.filter(
        (val) => val
      );
      item.common.contacts.telegrams = item.common.contacts.telegrams
        .filter((val) => val)
        .map((val) => formatTelegram(val));
      item.common.contacts.websites = item.common.contacts.websites.filter(
        (val) => val
      );
    }
    if (isAdd) {
      await api.post(`/dashboard/club/cards/`, item);
    } else {
      await api.patch(`/dashboard/club/cards/${cardId}`, item);
    }
    dispatch(updateCardInList(item));
    navigate(redirectTo);
    dispatch(updateOptions());
    dispatch(fetchManagement());
  };

  const update = (name, value) => {
    setData((prev) => {
      const data = { ...prev };
      set(data, name, value);
      return data;
    });
  };

  const invite = async () => {
    const cardsIds = [data.cardId];
    await api.post(`/dashboard/club/invite/`, {
      cardsIds,
    });
    dispatch(fetchManagement());
    fetchResident();
    setShowSuccessInvite(true);
    setShowInvite(false);
  };

  const canAddResource = (type, its) => {
    const { resources } = data;
    let items = [];
    if (type === 'spheres') {
      items = [...resources.spheres.regular, ...resources.spheres.other];
    }
    if (type === 'competences') {
      items = [
        ...resources.competences.regular,
        ...resources.competences.other,
      ];
    }
    if (type === 'lobbies') {
      items = [...resources.lobbies];
    }
    return items.length + its.length < 8;
  };

  const openInvite = () => {
    if (data.isUserExists) {
      navigate(`/club/invite-existing-from-card/${data.cardId}/?`);
    } else {
      setShowInvite(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setErrors(validateResident());
    }, 0);
  }, [data]);

  useEffect(() => {
    if (cardId) fetchResident();
  }, []);

  useEffect(() => {
    const tabs = getInvalidTabs();
    setInvalidTabs(tabs);
  }, [errors]);

  return (
    <>
      <Dialog open={true} onClose={onCancel} className="wide-dialog">
        <DialogTitle>
          {isAdd ? 'Добавление' : 'Карточка'} резидента
          <IconButton
            aria-label="close"
            onClick={onCancel}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close color="disabled" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ width: '832px', overflowX: 'hidden' }}
          className="paddingless"
        >
          <div className={styles.modal}>
            <div className={styles.tabs}>
              <SidebarTabs tabs={tabs} value={tab} onChange={setTab} />
            </div>
            <div className={styles.content}>
              {data ? (
                <ValidationProvider
                  value={{ errors, touched, touch, invalidTabs }}
                >
                  <ResidentProvider value={{ data, update, canAddResource }}>
                    {tab === 'common' && <Profile />}
                    {tab === 'spheres' && <Resource type="spheres" />}
                    {tab === 'competences' && <Resource type="competences" />}
                    {tab === 'lobbies' && <LobbyResource />}
                    {tab === 'events' && (
                      <Events isEmpty={isAdd || !data.userId} />
                    )}
                    {tab === 'karma' && (
                      <Karma
                        isEmpty={isAdd || !data.userId}
                        onUpdate={fetchResident}
                      />
                    )}
                    {tab === 'residency' && <Residency isAdd={isAdd} />}
                  </ResidentProvider>
                </ValidationProvider>
              ) : (
                <Box>
                  <CircularProgress />
                </Box>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className={styles.footer}>
          {data?.status === 'unauthorized' && !data.isInviteSent && (
            <>
              <AppButton color="secondary" onClick={openInvite}>
                Пригласить на платформу
              </AppButton>
              &nbsp;
            </>
          )}
          <AppButton
            color="primary"
            onClick={saveResident}
            disabled={!isChanged()}
          >
            {isAdd ? 'Добавить резидента' : 'Сохранить изменения'}
          </AppButton>
        </DialogActions>
      </Dialog>
      {showInvite && (
        <InviteModal
          onCancel={() => setShowInvite(false)}
          onSend={invite}
          items={[
            {
              name: `${data?.common?.about?.firstName} ${data?.common?.about?.lastName}`,
              email: data?.common?.contacts?.emails[0],
            },
          ]}
        />
      )}
      {showSuccessInvite && (
        <SuccessModal
          description="Приглашения отправлены."
          onClose={() => setShowSuccessInvite(false)}
        />
      )}
      {showCloseConfirmation && (
        <CloseConfirmation
          title="Добавление резидента"
          onSave={saveResident}
          onClear={closeModal}
          onClose={() => setShowCloseConfirmation(false)}
        />
      )}
    </>
  );
};
